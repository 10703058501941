<style lang='less'>
</style>
<template>
  <div class="search-list-vue frame-page h-panel">
    <div class="h-panel-bar"><span class="h-panel-title">斗法列表</span></div>
    <div class="h-panel-bar">
	  <div style="padding: 10px 0 20px;">
		  <Select style="width: 150px;float: left;" v-model="cx.cid" :datas="classlist" :filterable="true" keyName="cid" titleName="name" placeholder="请选择"></Select>
		  <Select style="width: 100px;float: left;" v-model="cx.status_text" :datas="statuslist" placeholder="请选择"></Select>
		  <Input :width="80" v-model="cx.school" placeholder="输入学校名字"></Input>&nbsp;
		  <Search :width="200" v-model="cx.qq" @search="get()" placeholder="输入账号或课程" showSearchButton></Search>&nbsp;
		  <!-- <Button color="yellow" @click="model2=true">添加玩耍小伙伴</Button> -->
	  </div>
    </div>

	<div class="h-panel-body">
	  <Table :loading="loading" :datas="row.data">
	    <TableItem title="序号" :width="70">
	      <template v-slot="{ index }">{{ index + 1 }} </template>
	    </TableItem>
		<TableItem title="操作" :width="100">
			<template v-slot="{ index }"><Button color="primary" @click="up=row.data[index];model1 = true;budan(row.data[index].oid);">重玩</Button> {{ row.data[index].text }} </template>
		</TableItem>
	    <TableItem prop="ptname" title="斗法项目" :width="120"></TableItem>
		<TableItem title="信息" :width="200">
			<template v-slot="{ index }">{{ row.data[index].school }} {{ row.data[index].user }} {{ row.data[index].pass }}</template>
		</TableItem>
		<TableItem prop="kcname" title="内容" :width="200"></TableItem>
		<TableItem title="状态" :width="100">
			<template #default="{ data }">
				<Tag color="#108ee9" v-if="data.status=='待处理'">{{data.status}}</Tag>
				<Tag color="yellow" v-else-if="data.status=='进行中'">{{data.status}}</Tag >
				<Tag color="#cf5e4d" v-else-if="data.status=='待考试'">{{data.status}}</Tag >
				<Tag color="#cf9e4d" v-else-if="data.status=='平时分'">{{data.status}}</Tag >
				<Tag color="#87d068" v-else-if="data.status=='已完成'">{{data.status}}</Tag >
				<Tag color="#f50" v-else-if="data.status=='异常'">{{data.status}}</Tag >
				<Tag color="gray" v-else-if="data.status=='已退单'">{{data.status}}</Tag >
				<Tag  v-else>{{data.status}}</Tag >
			</template>
		</TableItem>
		<TableItem prop="process" title="进度" :width="350"></TableItem>
		<TableItem prop="remarks" title="备注" :width="200"></TableItem>
		<TableItem prop="addtime" title="开始时间" :width="150"></TableItem>
	  </Table>
	  <Space :height="10" />
	  <Pagination v-if="row.total > 0" align="right" v-model="fengye" @change="changePage" />
	</div>
<!-- 	<Loading text="Loading" :loading="loading"></Loading> -->
	
<!-- 	<Modal v-model="model1">
	      <template #header>
	        正在为小伙伴 {{up.user}} 赠送饼干
	      </template>
	      <div>
			   <Input type="number" v-model="money" placeholder="输入饼干数量" style="width: 100%;"/>
		  </div>
	      <template #footer>
	        <Button color="primary" @click="modalConfirm">确认</Button>
	        <Button @click="model1=false">关闭</Button>
	      </template>
	</Modal>

	<Modal v-model="model2">
	      <template #header>
	        添加小伙伴
	      </template>
	      <div>
			   <Input type="text" v-model="add.user" placeholder="输入伙伴账号" style="width: 100%;"/>
			   <Input type="text" v-model="add.pass" placeholder="输入伙伴密码" style="width: 100%;"/>
		  </div>
	      <template #footer>
	        <Button color="primary" @click="adduser">确认</Button>
	        <Button @click="model2=false">关闭</Button>
	      </template>
	</Modal>	 -->
  </div>
</template>
<script>
import utils from '@common/utils';
import Request from '@common/request';
import { message, modal } from 'heyui';

export default {
  data() {
    return {
	  model1: false,
	  model2: false,
	  row:{
		data:[],
	  },
	  add:{
		  user:'',
		  pass:''
	  },
	  up:{},
      loading: true,
	  cx:{
		page:1,
		pagesize: 20,
		total: 0,
		qq:'',
		cid:'',
		status_text:'',
	  },
	  fengye:{
		  page:1,
		  size:20,
		  pagesize: 20,
		  total: 0,
	  },
	  classlist:[
		  {cid:'',name:'全部'}
	  ],
	  statuslist: [
		  {key:'',title:"全部"},
		  {key:'待处理',title:"待处理"},
		  {key:'进行中',title:"进行中"},
		  {key:'已完成',title:"已完成"},
		  {key:'待考试',title:"待考试"},
		  {key:'平时分',title:"平时分"},
		  {key:'异常',title:"异常"},
		  {key:'已退单',title:"已退单"},
	  ],
	  money:""
    };
  },
  mounted() {
    // this.get();
	this.get_class();
  },
  watch: {
    // params() {
	   // this.cx.type=this.params.type
	   // this.get();
    // }
  },
  methods: {
    changePage(pageinfo) {
	   console.log(pageinfo)
	   this.cx.page=pageinfo.page
	   this.cx.pagesize=pageinfo.size
	   this.get();
    },
	change_class(info){
		// console.log(info)
	},
    get: async function () {
      let res = await Request.Order.index({cx:this.cx});
      if(res.code==1){
		  this.row=res;
		  this.cx.total=res.total
		  this.fengye.total=res.total
		  // console.log(this.row);
		  this.loading = false; 
	  }
    },
	get_class: async function () {
	  let res = await Request.Order.get_class();
	  if(res.code==1){
		  this.classlist=res.data;
		  // this.cx.total=res.total
		  // console.log(this.classlist);
	  }
	  
	  this.get()
	},
	adduser(){
	    this.loading = true;
		if(this.add.user=='' || this.add.pass==''){
			message.error("所有项目不能为空");
		}
		Request.User.add({data:this.add}).then(res=>{
		  if(res.code==1){
			  message.success(res.msg);
			  this.get();
		  }else{
			  message.error(res.msg);
		  }
		});
		this.model2 = false;
		this.loading = false;
		
	},
	budan(oid){
		Request.Order.budan({oid:oid}).then(res=>{
		  if(res.code==1){
			  message.success(res.msg);
			  this.get();
		  }else{
			  message.error(res.msg);
		  }
		});
	},
	modalConfirm() {
	  this.loading = true;
	  Request.User.cz({uid:this.up.uid,money:this.money}).then(res=>{  
		  if(res.code==1){
		  		  message.success(res.msg);
		  		  this.get();
		  }else{
		  	  message.error(res.msg);
		  }
	  });
	  this.model1 = false;
	  this.loading = false;
	},
  },
  computed: {

  }
};
</script>
